//Import workers
import PushPushGo from './PushPushGo/PushPushGoWorker';
import ArticleCacheWorker from './ArticleCache/ArticleCacheWorker';

//Register new workers
const RegisteredWorkers = [
    PushPushGo,
    ArticleCacheWorker
];

//Run all workers
self.addEventListener("install", () => {
    for (let worker of RegisteredWorkers)
        worker(self);
});

import { checkArticle, cleanUpArticles, getArticle, getArticleCache, preloadArticle } from './ArticleCache';
import { IArticleCacheWorkerMessage, IArticleCacheWorkerMessageCheckData, IArticleCacheWorkerMessagePreloadData } from './ArticleCacheChannel';

let MESSAGE_CHANNELS: MessagePort[] = [];

export const sendMessageFromWorker = (message: IArticleCacheWorkerMessage) => {
    //Clear closed channels
    //MESSAGE_CHANNELS = MESSAGE_CHANNELS.filter(Boolean);

    MESSAGE_CHANNELS.forEach(channel => channel.postMessage(message));
};

const handleMessage = (event: MessageEvent, articleCache: Cache) => {
    console.log("[ES] ArticleCache message: ", event);

    const { command, data } = event.data as IArticleCacheWorkerMessage;

    switch (command) {
        case "PRELOAD": {
            const { slug, force } = data as IArticleCacheWorkerMessagePreloadData;
            preloadArticle(articleCache, slug, force);
            break;
        }

        case "CHECK": {
            const { slug } = data as IArticleCacheWorkerMessageCheckData;
            checkArticle(articleCache, slug);
            break;
        }
    }
};

const handleFetch = (event: FetchEvent, articleCache: Cache) => {
    const request = event.request;
    const url = new URL(request.url);

    const { pathname } = url;

    if (!pathname.startsWith("/pwa-article/"))
        return;

    event.respondWith(
        getArticle(articleCache, url)
    );
};

export default async (worker: Worker) => {
    //Open cache
    try {
        const articleCache = await getArticleCache();
        console.log("[ES] ArticleCache - ready");

        //Cleanup cache
        cleanUpArticles(articleCache);

        //Interact with fetch
        worker.addEventListener("fetch", (event) => {
            handleFetch(event as FetchEvent, articleCache);
        });

        //Communication with store
        worker.addEventListener('message', (event) => {
            if (event.data && event.data.type == "ARTICLE_CACHE_PORT_INIT") {
                console.log("[ES] ArticleCache - connected");
                let ArticleCacheChannel = event.ports[0];
                ArticleCacheChannel.onmessage = (event) => {
                    handleMessage(event, articleCache);
                };
                MESSAGE_CHANNELS.push(ArticleCacheChannel);
            }
        });
    } catch {
        console.error("[ES] ArticleCache - failed to start");
    }
};
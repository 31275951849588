const PushPushGoBrandsConfig = {
    aszdziennik: "https://s-eu-1.pushpushgo.com/66952b77dcfe8d618f9131d1/worker.js",
    dadhero: "https://s-eu-1.pushpushgo.com/66952b6335487da09214c9ce/worker.js",
    innpoland: "https://s-eu-1.pushpushgo.com/66952b2fc05a73d2a0685f89/worker.js",
    mamadu: "https://s-eu-1.pushpushgo.com/66952b52603d31a48459e073/worker.js",
    natemat: "https://s-eu-1.pushpushgo.com/66951fb535487da0920f67f1/worker.js",
    topnewsy: "https://s-eu-1.pushpushgo.com/6698f5e917ed67a925e0f722/worker.js"
}

Object.entries(PushPushGoBrandsConfig).forEach(([brandName, pushPushWorkerUrl]) => {
    if (self.location.origin.includes(brandName))
        //@ts-ignore
        importScripts(pushPushWorkerUrl);
});

export default async (worker: Worker) => {
    console.log("[ES] PushPushGo - ready");
};
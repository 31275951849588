import { IArticleCacheWorkerMessageReloadData, IArticleCacheWorkerMessageStatusData } from "./ArticleCacheChannel";
import { sendMessageFromWorker } from "./ArticleCacheWorker";

const CACHED_TIME = 15 * 60 * 1000; //15min cache
const CLEANUP_TIME = 48 * 60 * 60 * 1000; //48h cleanup

interface IFetchQueue {
    [slug: string]: {
        response: Promise<Response>;
        clients: number;
    }
}

let FETCH_QUEUE: IFetchQueue = {};

export const getArticleCache = async () => {
    return await caches.open("pwa-article");
}

export const fetchArticle = async function (articleCache: Cache, slug: string, url?: URL) {
    const fetchURL = url?.toString() || `/pwa-article/${slug}`;

    const response = await fetch(fetchURL);

    if (!response.ok)
        return response;

    const responseToStore = response.clone();
    await articleCache.put(slug, responseToStore);

    //Store metadata in another registry
    await articleCache.put(slug + '--metadata', new Response(JSON.stringify({
        slug: slug,
        cachedTime: Date.now()
    })));

    return response;
}

export const storeArticle = async function (articleCache: Cache, slug: string, url?: URL, force?: boolean) {
    const articleResponse = await articleCache.match(slug);

    //Do not store request with GET params
    if (url && url.search)
        force = true;

    if (!force)
        console.log('[ES] ArticleCache get stored ', slug, articleResponse);

    if (articleResponse && !force) {

        const resposeMetadata = await (await articleCache.match(slug + '--metadata'))?.json();

        const cachedTime = parseInt(resposeMetadata?.cachedTime ?? '0');
        const updateTime = Date.now() - cachedTime;

        if (cachedTime > 0) {
            //Check if cached data was generated x ago
            if (updateTime > CACHED_TIME) {
                console.log("[ES] ArticleCache - reload ", updateTime);
                fetchArticle(articleCache, slug, url).then(() => {
                    sendMessageFromWorker({
                        command: "RELOAD",
                        data: {
                            slug
                        } as IArticleCacheWorkerMessageReloadData
                    });
                });
            }

            return articleResponse;
        }
    }

    return await fetchArticle(articleCache, slug, url);
}

export const getArticle = async function (articleCache: Cache, url: URL) {
    const { pathname, search } = url;
    const slug = pathname.replace("/pwa-article/", "");

    let response;

    //Do not queue request with GET params
    if (search)
        return storeArticle(articleCache, slug, url);

    if (FETCH_QUEUE.hasOwnProperty(slug)) {
        FETCH_QUEUE[slug].clients++;

        response = FETCH_QUEUE[slug].response;
    } else {
        response = storeArticle(articleCache, slug, url);
        FETCH_QUEUE[slug] = {
            response: response,
            clients: 1
        };
    }

    const articleResponse = (await response).clone();
    FETCH_QUEUE[slug].clients--;

    if (FETCH_QUEUE[slug].clients <= 0)
        delete FETCH_QUEUE[slug];

    return articleResponse;
}

export const cleanUpArticles = async function (articleCache: Cache) {
    const cachedArticles = await articleCache.matchAll();

    let outdated: string[] = [];
    for (const item of cachedArticles) {
        const cacheItem = item.clone();

        //Ignore cache request with url - metadata do not have url
        if (cacheItem.url)
            continue;

        const cacheItemMetadata = await cacheItem.json();

        const cachedTime = parseInt(cacheItemMetadata.cachedTime ?? '0');
        const elapsedTime = Date.now() - cachedTime;

        if (elapsedTime > CLEANUP_TIME)
            outdated.push(
                cacheItemMetadata.slug
            )
    }

    //Remove all outdated articles
    outdated.forEach((slug) => {
        articleCache.delete(slug);
        articleCache.delete(slug + '--metadata');
    });
}

export const checkArticle = async function (articleCache: Cache, slug: string) {
    const articleData = (await articleCache.match(slug))?.clone();

    let messageData = {
        slug,
        available: !!articleData
    } as IArticleCacheWorkerMessageStatusData;

    if (articleData)
        messageData['articleData'] = await (articleData.json());

    sendMessageFromWorker({
        command: "STATUS",
        data: messageData
    });


}

export const preloadArticle = async function (articleCache: Cache, slug: string, force?: boolean) {
    if (force) {
        await storeArticle(articleCache, slug, undefined, true);
        return;
    }

    const articleData = await articleCache.match(slug);
    if (articleData)
        return articleData;

    return await storeArticle(articleCache, slug, undefined, false);
}